<template>
    <b-modal :title="modalTitle" v-model="modalActive" size="lg" no-close-on-backdrop @hide="clearData">
        <template #default>
            <validation-observer ref="validation">
                <b-form-group :label="$t('form_data.name2')">
                    <validation-provider #default="{ errors }" :name="$t('form_data.name2')" rules="required">
                        <b-form-input autofocus v-model="addObject.name"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>

                <b-form-group :label="$t('reporting.issued_bills.tax')">
                    <validation-provider #default="{ errors }" :name="$t('reporting.issued_bills.tax')"
                                         rules="required">
                        <v-select v-model="addObject.vat_group_id" appendToBody label="name"
                                  :options="vats" :reduce="item => item.id"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>

                <b-form-group :label="$t('invoices.price')" class="w-100">
                    <validation-provider #default="{ errors }" :name="$t('invoices.price')" rules="required|decimalCustom:2|mustBePositive:true|min_number: 0 ">
                        <b-input-group class="inputPrice" append="€">
                            <b-form-input  type="number" :value="salePrice" v-on:input="changeSalePrice"/>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>

                <b-row>
                    <b-col>
                        <validation-provider #default="{ errors }" :name="$t('keys.color')">
                            <colorSelector  v-model="addObject.color" :product-name="addObject.name" ></colorSelector>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>
                </b-row>
            </validation-observer>

        </template>

        <template #modal-footer> 
            <b-button variant="primary" @click="validate">
                <span v-if="editing">
                    <feather-icon icon="EditIcon" class="mr-50"/>
                    <span class="align-middle">{{ $t('general.edit') }}</span>
                </span>
                <span v-else>
                    <feather-icon icon="PlusIcon" class="mr-50"/>
                    <span class="align-middle">{{ $t('general.add') }}</span>
                </span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>
    import {BButton, BCol, BFormGroup, BFormInput, BInputGroup, BModal, BRow} from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required } from '@core/utils/validations/validations'
    import * as Sentry from '@sentry/vue'
    import ColorSelector from '@/views/ColorSelector/ColorSelector.vue'
    import vSelect from 'vue-select'

    export default {
        components: {
            ColorSelector,
            BCol,
            BRow,
            BInputGroup,
            BFormGroup,
            BFormInput,
            BModal,
            BButton,
            ValidationProvider,
            ValidationObserver,
            vSelect

        },
        props: {
            productsRaw: {
                type: Array
            },
            vats:{
                type:Array
            }
        },
        data() {
            return {
                arraysLoad: false,
                modalActive: false,
                addObject: {
                    name: '',
                    color:'#545454',
                    price:'',
                    vat_group_id:''
                },
                required,
                editing: false,
                salePrice:''
            }
        },
        computed:{
            modalTitle() {
                return this.editing ? this.$t('keys.edit_returnable_packaging_type') : this.$t('keys.add_returnable_packaging_type')
            }
        },
        methods: {
            changeSalePrice(val) {
                this.salePrice = JSON.parse(JSON.stringify(val))
                this.addObject.price = Number(Number(Number(this.salePrice) * 1000000))
            },
            open(data = null) {
                if (data !== null) {
                    this.addObject = data
                    this.salePrice = Number(Number(Number(this.addObject.price) / 1000000))

                    this.editing = true
                } else {
                    this.editing = false
                    this.addObject = {
                        name: '',
                        color:'#545454',
                        price:'',
                        vat_group_id:''
                    }


                }
                this.modalActive = true
            },
            validate() {
                this.$refs.validation.validate().then((success) =>  {
                    if (success) {
                        if (this.editing) this.edit()
                        else this.add()
                    }
                })
            },
            async add() {
                try {
                    this.addObject.business_unit_id = this.selectedBusinessUnitId
                    const response = await this.$http.put('/api/client/v1/returnable_packaging/type', this.addObject)
                    this.modalActive = false
                    this.$emit('itemAdded', response.data.id)
                    this.$printSuccess(this.$t('print.success.add'))
                    this.addObject = {
                        name: '',
                        color:'#545454',
                        price:'',
                        vat_group_id:''
                    }
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_add'))
                }
            },
            clearData() {
                this.addObject = {
                    name: '',
                    color:'#545454',
                    price:'',
                    vat_group_id:''
                }
                this.salePrice = 0
            },

            async edit() {
                try {
                    await this.$http.put(`/api/client/v1/returnable_packaging/type/${this.addObject.id}`, this.addObject)
                    this.modalActive = false
                    this.$emit('itemAdded')
                    this.$printSuccess(this.$t('print.success.edit'))
                    this.addObject = {
                        name: '',
                        color:'#545454',
                        price:'',
                        vat_group_id:''
                    }
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_edit'))
                }
            }
        }
    }
</script>
