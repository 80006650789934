var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":_vm.modalTitle,"size":"lg","no-close-on-backdrop":""},on:{"hide":_vm.clearData},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('validation-observer',{ref:"validation"},[_c('b-form-group',{attrs:{"label":_vm.$t('form_data.name2')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form_data.name2'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"autofocus":""},model:{value:(_vm.addObject.name),callback:function ($$v) {_vm.$set(_vm.addObject, "name", $$v)},expression:"addObject.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('reporting.issued_bills.tax')}},[_c('validation-provider',{attrs:{"name":_vm.$t('reporting.issued_bills.tax'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"appendToBody":"","label":"name","options":_vm.vats,"reduce":function (item) { return item.id; }},model:{value:(_vm.addObject.vat_group_id),callback:function ($$v) {_vm.$set(_vm.addObject, "vat_group_id", $$v)},expression:"addObject.vat_group_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"w-100",attrs:{"label":_vm.$t('invoices.price')}},[_c('validation-provider',{attrs:{"name":_vm.$t('invoices.price'),"rules":"required|decimalCustom:2|mustBePositive:true|min_number: 0 "},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"inputPrice",attrs:{"append":"€"}},[_c('b-form-input',{attrs:{"type":"number","value":_vm.salePrice},on:{"input":_vm.changeSalePrice}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":_vm.$t('keys.color')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('colorSelector',{attrs:{"product-name":_vm.addObject.name},model:{value:(_vm.addObject.color),callback:function ($$v) {_vm.$set(_vm.addObject, "color", $$v)},expression:"addObject.color"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)]},proxy:true},{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.validate}},[(_vm.editing)?_c('span',[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"EditIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('general.edit')))])],1):_c('span',[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('general.add')))])],1)])]},proxy:true}]),model:{value:(_vm.modalActive),callback:function ($$v) {_vm.modalActive=$$v},expression:"modalActive"}})}
var staticRenderFns = []

export { render, staticRenderFns }