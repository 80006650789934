var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.showLoader}},[_c('b-card',{staticClass:"my-1 d-flex align-center",attrs:{"title":_vm.$t('keys.returnable_packaging_type')}},[_c('CustomTable',{ref:"packagingTable",staticClass:"mt-1",attrs:{"fields":_vm.columnDefs,"api-url":"/api/client/v1/returnable_packaging/type"},scopedSlots:_vm._u([(_vm.$hasPermission(_vm.$permissions.ReturnablePackagingWrite))?{key:"buttons",fn:function(){return [_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$refs.addModal.open()}}},[_c('feather-icon',{attrs:{"icon":"PlusSquareIcon"}}),_vm._v(" "+_vm._s(_vm.$t('keys.add_returnable_packaging_type'))+" ")],1)]},proxy:true}:null,{key:"price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$numberRounding(item.price))+"€ ")]}},{key:"color",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{staticClass:"w-100",staticStyle:{"border":"1px solid #292929"},style:([{'background-color':item.color},{'color':item.color}])},[_vm._v(_vm._s(item.color))])]}},{key:"vat_group_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getVatName(item.vat_group_id))+" ")]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-button',{staticClass:"mr-1 m-0 button",attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.remove(item.id,item.name)}}},[_vm._v(" "+_vm._s(_vm.$t('general.delete'))+" ")]),_c('b-button',{staticClass:"mr-1 m-0 button",attrs:{"variant":"warning","size":"sm"},on:{"click":function($event){return _vm.editRow(item)}}},[_vm._v(" "+_vm._s(_vm.$t('general.edit'))+" ")])],1)]}}],null,true)})],1),_c('returnable-packaging-types-modal',{ref:"addModal",attrs:{"vats":_vm.vats},on:{"itemAdded":_vm.loadData}}),_c('UsedAtModal',{ref:"usedAtModal",on:{"confirm":_vm.deleteItem,"cancel":_vm.cancelDelete}},[(_vm.products)?_c('p',[_vm._v(" "+_vm._s(_vm.$t('keys.products.product'))+" "),_c('b',[_vm._v(_vm._s(this.returnablePackagingName))]),_vm._v(" "+_vm._s(_vm.$t('keys.returnable_packaging.disable_returnable_packaging_middle'))+" ("),_c('b',[_vm._v(_vm._s(_vm.products.length))]),_vm._v(") "+_vm._s(_vm.$t('keys.ingredients.disable_ingredient_end'))+". ")]):_vm._e(),(_vm.products && _vm.products.length > 0)?_c('p',[_vm._v(" "+_vm._s(_vm.$t('keys.ingredients.products_list'))+": ")]):_vm._e(),(_vm.products)?_c('div',[_c('ul',[_vm._l((_vm.products.slice(0,5)),function(item){return _c('li',{key:item},[_vm._v(_vm._s(item))])}),(_vm.products.length > 5)?_c('li',[_vm._v("...")]):_vm._e()],2)]):_vm._e(),_c('p',[_vm._v(" "+_vm._s(_vm.$t('keys.returnable_packaging.returnable_packaging_disable_info'))+". ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('general.wish_continue'))+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }