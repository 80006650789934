<template>
    <b-overlay :show="showLoader">
        <b-card class="my-1 d-flex align-center" :title="$t('keys.returnable_packaging_type')">
            <CustomTable  ref="packagingTable" class="mt-1" :fields="columnDefs" api-url="/api/client/v1/returnable_packaging/type">
                <template #buttons v-if="$hasPermission($permissions.ReturnablePackagingWrite)">
                    <b-button variant="primary" @click="$refs.addModal.open()">
                        <feather-icon icon="PlusSquareIcon"/>
                        {{ $t('keys.add_returnable_packaging_type') }}
                    </b-button>
                </template>
                <template #price="{item}">
                    {{$numberRounding(item.price)}}€
                </template>
                <template #color="{item}">
                    <b-badge class="w-100" style="border: 1px solid #292929" :style="[{'background-color':item.color},{'color':item.color}]">{{item.color}}</b-badge>
                </template>
                <template #vat_group_id="{item}">
                    {{getVatName(item.vat_group_id)}}
                </template>

                <template #actions="{item}">
                    <div class="d-flex justify-content-center">
                        <b-button class="mr-1 m-0 button" variant="danger" size="sm" @click="remove(item.id,item.name)">
                            {{ $t('general.delete') }}
                        </b-button>
                        <b-button class="mr-1 m-0 button" variant="warning" size="sm" @click="editRow(item)">
                            {{ $t('general.edit') }}
                        </b-button>

                    </div>
                </template>
            </CustomTable>
        </b-card>
        <returnable-packaging-types-modal ref="addModal" @itemAdded="loadData" :vats="vats"></returnable-packaging-types-modal>
        <UsedAtModal ref="usedAtModal" @confirm="deleteItem" @cancel="cancelDelete">
            <p v-if="products">
                {{$t('keys.products.product')}} <b>{{this.returnablePackagingName}}</b> {{$t('keys.returnable_packaging.disable_returnable_packaging_middle')}}
                (<b>{{products.length}}</b>) {{$t('keys.ingredients.disable_ingredient_end')}}.
            </p>
            <p v-if="products && products.length > 0">
                {{$t('keys.ingredients.products_list')}}:
            </p>
            <div v-if="products">
                <ul>
                    <li v-for="item in products.slice(0,5)" :key="item">{{ item }}</li>
                    <li v-if="products.length > 5">...</li>
                </ul>
            </div>
            <p>
                {{$t('keys.returnable_packaging.returnable_packaging_disable_info')}}.
            </p>
            <p>
                {{$t('general.wish_continue')}}
            </p>
        </UsedAtModal>
    </b-overlay>
</template>

<script>
    import {BBadge, BButton, BCard, BOverlay} from 'bootstrap-vue'
    import * as Sentry from '@sentry/vue'
    import ReturnablePackagingTypesModal from '@/views/ReturnablePackagingTypes/components/ReturnablePackagingTypesModal.vue'
    import CustomTable from '@/components/CustomTable.vue'
    import UsedAtModal from '@/views/components/UsedAtModal.vue'

    export default {
        components: {UsedAtModal, BBadge, CustomTable, ReturnablePackagingTypesModal,  BCard, BOverlay, BButton},
        data() {
            return {
                productsRaw: [],
                vats: [],
                showLoader: false,
                arraysLoad: false,
                products:[],
                returnablePackagingName:'',
                returnablePackagingDeleteId:null
            }
        },
        methods: {
            async loadData() {
                try {
                    this.showLoader = true
                    await this.$refs.packagingTable.loadData()
                    const response = await this.$http.get('/api/client/v1/vat_groups/')
                    this.vats = response.data ?? []
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            editRow(data) {
                this.$refs.addModal.open(data)
            },
            getVatName(value) {
                if (value) {
                    const item = this.vats.find(x => x.id === value)
                    if (item) return item.name
                }
                return '/'
            },
            cancelDelete() {
                this.returnablePackagingName = ''
                this.products = []
                this.returnablePackagingDeleteId = null
            },
            async getProductsWithReturnablePackaging(id) {
                try {
                    this.showLoader = true
                    await this.$refs.packagingTable.loadData()
                    const response = await this.$http.get(`/api/client/v1/returnable_packaging/product/${id}`)
                    const prod = response.data || []
                    if (prod.length > 0) {
                        prod.forEach((item) => {
                            this.products.push(item.name)
                        })
                    }
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            async remove(id, name) {
                await this.getProductsWithReturnablePackaging(id)
                this.returnablePackagingDeleteId = id
                this.returnablePackagingName = name
                this.$refs.usedAtModal.show()
            },
            async deleteItem() {
                try {
                    this.products = []
                    await this.$http.delete(`/api/client/v1/returnable_packaging/type/${this.returnablePackagingDeleteId}`)
                    this.returnablePackagingDeleteId = null
                    this.returnablePackagingName = ''
                    this.$printSuccess(this.$t('print.success.delete'))
                    await this.$refs.packagingTable.clearFilters()

                    await this.loadData()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_delete'))
                }
            }
        },
        computed: {
            columnDefs() {
                const hasPermission =  this.$hasPermission(this.$permissions.ReturnablePackagingWrite)
                const defs = [
                    {
                        key: 'packaging_type_code', // kaj kaze v tabeli
                        label:  this.$t('table_fields.code'),
                        filter: true,
                        filterType: 'input',
                        filterKey: 'packaging_type_code.w',
                        permissions: true
                    },
                    {
                        key: 'name',
                        label: this.$t('table_fields.name'),
                        filter: true,
                        filterType: 'input',
                        filterKey: 'name.w',
                        permissions: true
                    },
                    {
                        key: 'price',
                        label: this.$t('invoices.price'),
                        filter: false,
                        permissions: true
                    },

                    {
                        key: 'color',
                        label: this.$t('table_fields.color'),
                        filter: false,
                        permissions: true
                    },
                    {
                        key: 'vat_group_id',
                        label: this.$t('reporting.issued_bills.tax'),
                        filter: false,
                        permissions: true
                    }
                ]

                if (hasPermission) {
                    defs.push(
                        {
                            key: 'actions',
                            label: this.$t('table_fields.actions'),
                            permissions: true
                        }
                    )
                }
                return defs
            }
        },

        async mounted() {
            await this.loadData()
        }
    }
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>